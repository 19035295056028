<template>
  <div class="extration-bdd-frais">
    <b-button
      size="sm"
      class="button-export-style  ml-2"
      v-b-modal.extract-modal
      title="Télecharger  les factures"
      @click.prevent="getFields"
    >
      <font-awesome-icon icon="cloud-download-alt"
    /></b-button>
    <!-- Extract BDD Modal -->
    <b-modal
      ref="extract-modal"
      id="extract-modal"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Générer un ' + typeModal"
      @hidden="hideModal('extract-modal')"
      modal-class="modal-extaction-bdd-frais"
    >
      <b-form-group
        id="fieldset-horizontal-type"
        label-cols-sm="5"
        label-cols-lg="3"
        content-cols-sm
        content-cols-lg="9"
        label="Type de fichier : "
        label-for="type-modal"
      >
        <b-form-select
          id="fieldset-horizontal-type"
          v-model="typeModal"
          :options="ListType"
          required
          class="b-form-select-raduis "
        ></b-form-select>
      </b-form-group>
      <b-form-group
        v-if="typeModal == 'xls'"
        id="fieldset-horizontal"
        label-cols-sm="5"
        label-cols-lg="3"
        content-cols-sm
        content-cols-lg="9"
        label="Modèle : "
        label-for="extract-modal"
      >
        <b-form-select
          id="extract-modal"
          v-model="selectedModel"
          :options="ListModelToExtract"
          required
          class="b-form-select-raduis "
        ></b-form-select>
      </b-form-group>
      <div
        v-if="getLoadingExportExcelHeadersData"
        class="chargement-message-text"
      >
        Chargement en cours
      </div>
      <div class="row">
        <div
          class="col-6"
          v-if="typeModal == 'xls' && getLoadingExportExcelHeadersData == false"
        >
          <b-form-group
            id="fieldset-headers-horizontal"
            label="Colonnes à afficher dans Excel: "
            label-for="extract-modal"
          >
            <b-form-checkbox
              v-show="getColumnsFieldsFraisExcelExport.length"
              v-model="allColums"
              switch
              @change="changeAllCheckColumns"
              >Tous</b-form-checkbox
            >

            <b-form-checkbox-group
              switches
              stacked
              id="checkbox-group-1"
              v-model="selectedColumns"
              :options="getColumnsFieldsFraisExcelExport"
              name="flavour-1"
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
        <div
          class="col-6"
          v-if="typeModal == 'xls' && getLoadingExportExcelHeadersData == false"
        >
          <b-form-group
            id="fieldset-colonne-horizontal"
            label="Pour : "
            label-for="extract-modal"
          >
            <b-form-checkbox
              v-show="getHeadersFieldsFraisExcelExport"
              v-model="allHeaders"
              switch
              @change="changeAllCheckHeaders"
              >Tous</b-form-checkbox
            >

            <b-form-checkbox-group
              switches
              stacked
              id="checkbox-group-2"
              v-model="selectedHeaders"
              :options="getHeadersFieldsFraisExcelExport"
              name="flavour-1"
              value-field="column"
              text-field="column_name"
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>

      <div class="form-modal-custom-style mt-2">
        <div class="messageError">
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button
            class="button-valide-style "
            @click="handleExportFile"
            :disabled="typeModal == 'xls' && getLoadingExportExcelHeadersData"
          >
            <span>
              Générer
              <div
                v-if="getLoadingExtactBddFraisVisionFiliale"
                class="loading ml-2"
              >
                <div class="spinner-border" role="status"></div></div
            ></span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- END Extract BDD Modal -->
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    filterType: {
      default: null
    },
    filteryear: { reduired: true },
    filterMonth: { reduired: true },
    dataToUse: { required: true },
    curentInterface: { required: false },
    filter: { required: true },
    selected: { required: true }
  },
  data() {
    return {
      selectedModel: 'vertical',
      ListYear: [],
      error: null,
      ListModelToExtract: [
        // { value: 'standard', text: 'Exports Standards' },
        { value: 'vertical', text: 'Verticale' },
        { value: 'horizontal', text: 'Horizontale' }
      ],

      typeModal: 'xls',
      selectedColumns: [],
      selectedHeaders: [],
      allColums: true,
      allHeaders: true
    };
  },
  methods: {
    ...mapActions([
      'exportExcelFraisVisionFilialeTh',
      'exportPdfFraisVisionFraisTh',
      'getHeadersAndColumnsForExcel'
    ]),
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.selectedModel = 'vertical';
      this.error = null;
      this.typeModal = 'xls';
      this.selectedColumns = [];
      this.selectedHeaders = [];
      this.allColums = true;
      this.allHeaders = true;
    },
    async handleExportFile() {
      if (this.typeModal == 'xls') {
        this.error = false;
        var bodyFormData = new FormData();
        if (this.selectedColumns.length == 0) {
          this.error = 'Sélectionne au moins une colonne ';
          return;
        }
        if (this.selectedHeaders.length == 0) {
          this.error = 'Sélectionne au moins un support ';
          return;
        }
        let dataToUseBE = [];
        dataToUseBE = this.dataToUse.filter(k => {
          return k.projects.some(e => e.check == true);
        });

        if (this.selected && this.selected > 0) {
          let indexToUse = 0;
          for (let j = 0; j < dataToUseBE.length; j++) {
            for (let i = 0; i < dataToUseBE[j].projects.length; i++) {
              if (dataToUseBE[j].projects[i].check == true) {
                bodyFormData.append(
                  'ids[' + [indexToUse] + ']',
                  dataToUseBE[j].projects[i].id
                );

                indexToUse = indexToUse + 1;
              }
            }
          }
        } else {
          Object.keys(this.filter).forEach(key => {
            if (this.filter[key] != null && this.filter[key] != 'type') {
              bodyFormData.append(key, this.filter[key]);
            }
          });
        }
        for (let j = 0; j < this.selectedHeaders.length; j++) {
          bodyFormData.append(
            'excel_headers[' + j + ']',
            this.selectedHeaders[j]
          );
        }
        for (let j = 0; j < this.selectedColumns.length; j++) {
          bodyFormData.append(
            'excel_columns[' + j + ']',
            this.selectedColumns[j]
          );
        }
        bodyFormData.append('type', this.selectedModel);
        const response = await this.exportExcelFraisVisionFilialeTh(
          bodyFormData
        );
        if (response) {
          this.hideModal('extract-modal');
        }
      } else {
        var bodyFormData = new FormData();
        let dataToUseBE = [];
        dataToUseBE = this.dataToUse.filter(k => {
          return k.projects.some(e => e.check == true);
        });
        // if (dataToUseBE.length == 0) {
        //   dataToUseBE = this.dataToUse;
        // }

        let indexToUse = 0;
        for (let j = 0; j < dataToUseBE.length; j++) {
          for (let i = 0; i < dataToUseBE[j].projects.length; i++) {
            if (this.filterType != null) {
              if (
                dataToUseBE[j].projects[i][this.computedFields] &&
                dataToUseBE[j].projects[i][this.computedFields] != null &&
                dataToUseBE[j].projects[i].check == true
              ) {
                bodyFormData.append(
                  'ids[' + [indexToUse] + ']',
                  dataToUseBE[j].projects[i][this.computedFields]
                );
              }
              indexToUse = indexToUse + 1;
            } else {
              for (let y = 0; y < this.computedFields.length; y++) {
                if (
                  dataToUseBE[j].projects[i][this.computedFields[y]] &&
                  dataToUseBE[j].projects[i][this.computedFields[y]] != null &&
                  dataToUseBE[j].projects[i].check == true
                ) {
                  bodyFormData.append(
                    'ids[' + [indexToUse] + ']',
                    dataToUseBE[j].projects[i][this.computedFields[y]]
                  );
                  indexToUse = indexToUse + 1;
                }
              }
            }
            indexToUse = indexToUse + 1;
          }
        }
        const response = await this.exportPdfFraisVisionFraisTh(bodyFormData);
        if (response) {
          this.hideModal('extract-modal');
        }
      }
    },
    async getFields() {
      let curentInterface = this.curentInterface
        ? this.curentInterface
        : 'Gestion Globale de frais';
      const response = await this.getHeadersAndColumnsForExcel(curentInterface);
      if (response) {
        this.getHeadersFieldsFraisExcelExport.map(item =>
          this.selectedHeaders.push(item.column)
        );
        this.selectedColumns = this.getColumnsFieldsFraisExcelExport;
      }
    },
    changeAllCheckHeaders() {
      this.selectedHeaders = this.allHeaders
        ? (this.selectedHeaders = this.getHeadersFieldsFraisExcelExport.map(
            item => {
              return item.column;
            }
          ))
        : [];
    },
    changeAllCheckColumns() {
      this.selectedColumns = this.allColums
        ? this.getColumnsFieldsFraisExcelExport.slice()
        : [];
    }
  },
  computed: {
    ...mapGetters([
      'getLoadingExtactBddFraisVisionFiliale',
      'getLoadingExportExcelHeadersData',
      'getHeadersFieldsFraisExcelExport',
      'getColumnsFieldsFraisExcelExport'
    ]),
    ListType() {
      return [
        { value: 'xls', text: 'xls' },
        {
          value: 'pdf',
          text: 'pdf',
          disabled: this.selected > 0 ? false : true
        }
      ];
    },
    computedFields() {
      switch (this.filterType) {
        case 'NOVA':
          return 'operationnel_id';
        case 'CACTUS':
          return 'commercial_id';
        case 'AXELIUS':
          return 'marketing_id';
        case 'ISO 33':
          return 'technique_id';
        case 'CERTILIA':
          return 'rge_id';
        case 'GE CDG':
          return 'CDG_id';
        case 'SAMI-B':
          return 'administratif_sav_id';
        case 'WEB ABONDANCE':
          return 'dsi_id';
        case 'CORPEDYS':
          return 'juridique_id';
        case 'ALTEO France':
          return 'alger_id';
        case 'Alteo contrôle qualité':
          return 'alteo_control_gestion_id';
        case 'NOVAE MAIRIE':
          return 'mairie_id';
        case 'GSE':
          return 'maintenance_pac_id';
        case 'UPCUBE TECHNOLOGIES':
          return 'upcube_id';
        default:
          return [
            'operationnel_id',
            'commercial_id',
            'marketing_id',
            'technique_id',
            'rge_id',
            'CDG_id',
            'administratif_sav_id',
            'dsi_id',
            'juridique_id',
            'alger_id',
            'mairie_id',
            'maintenance_pac_id',
            'upcube_id',
            'alteo_control_gestion_id'
          ];
      }
    }
    // computedColumn() {
    //   switch (this.filterType) {
    //     case 'NOVA':
    //       return 'operationnel';
    //     case 'CACTUS':
    //       return 'commercial';
    //     case 'AXELIUS':
    //       return 'marketing';
    //     case 'ISO 33':
    //       return 'technique';
    //     case 'CERTILIA':
    //       return 'rge';
    //     case 'GE CDG':
    //       return 'CDG';
    //     case 'SAMI-B':
    //       return 'administratif_sav';
    //     case 'WEB ABONDANCE':
    //       return 'dsi';
    //     case 'CORPEDYS':
    //       return 'juridique';
    //     case 'ALTEO France':
    //       return 'alger';
    //     case 'NOVAE MAIRIE':
    //       return 'mairie';
    //     case 'GSE':
    //       return 'maintenance_pac';
    //     case 'UPCUBE TECHNOLOGIES':
    //       return 'upcube';
    //     default:
    //       return null;
    //   }
    // }
  },
  mounted() {
    var courantYear = moment().year();
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
  }
};
</script>

<style scoped lang="scss"></style>
<style lang="scss">
.align-item-extract-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-extaction-bdd-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-colonne-horizontal,
  #fieldset-headers-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
</style>
