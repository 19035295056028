import { render, staticRenderFns } from "./exractionBdd.vue?vue&type=template&id=6a0e0563&scoped=true&"
import script from "./exractionBdd.vue?vue&type=script&lang=js&"
export * from "./exractionBdd.vue?vue&type=script&lang=js&"
import style1 from "./exractionBdd.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a0e0563",
  null
  
)

export default component.exports